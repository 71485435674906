import * as ACTIONS from 'ReduxLoop/newsletters/unsubscription/newsletterUnsubscriptionActions';
import { removeNotification } from 'ReduxLoop/notifications/notificationActions';

export class myNewsletterUnsubscription {
  constructor($scope, $ngRedux, settings, $state, $stateParams) {
    'ngInject';

    this.$scope = $scope;
    this.$state = $state;
    this.$stateParams = $stateParams;
    this.$ngRedux = $ngRedux;
    this.settings = settings;

    this.unsubscribe = this.$ngRedux.connect((state) => {
      return {
        showUnsubscriptionFeedbackForm:
          state.vm.newsletterUnsubscription.showUnsubscriptionFeedbackForm,
        unsubscriptionSuccessed: state.vm.newsletterUnsubscription.unsubscriptionSuccessed,
        unsubscribeReasons: state.vm.newsletterUnsubscription.unsubscribeReasons,
        notificationList: state.vm.notifications,
        loading: state.vm.newsletterUnsubscription.loading,
        feedbackSavedSuccessfully: state.vm.newsletterUnsubscription.feedbackSavedSuccessfully,
      };
    })(this);
  }

  $onInit() {
    this.secret = this.$stateParams.secret;

    if (this.secret) {
      this.$ngRedux.dispatch(ACTIONS.newsletterUnsubscribe(this.secret)).then(() => {
        this.$ngRedux.dispatch(ACTIONS.getNewsletterUnsubscribeReasons());
      });
    }

    this.reasons = [];
    this.atLeastOneReason = false;
  }

  $onDestroy() {
    this.unsubscribe();
  }

  removeNotification(ev, notificationKey) {
    this.$ngRedux.dispatch(removeNotification(notificationKey));
  }

  reasonChanged(_event, value, reasonKey) {
    if (value) {
      this.reasons.push(reasonKey);
    } else {
      this.reasons = this.reasons.filter((key) => key !== reasonKey);
    }

    if (reasonKey === 'andere') {
      this.otherReason = value;
      this.otherReasonText = '';
    }

    this.atLeastOneValidReason =
      this.reasons.length > 0 &&
      (!this.reasons.find((key) => key === 'andere') ||
        (this.reasons.find((key) => key === 'andere') && this.otherReasonText.length > 0));
  }

  otherReasonChanged() {
    this.atLeastOneValidReason = this.otherReasonText.length > 0;
  }

  setUnsubscribeReasons() {
    this.$ngRedux.dispatch(
      ACTIONS.newsletterSetUnsubscribeReasons(
        this.secret,
        this.reasons.filter((key) => key !== 'andere'),
        this.otherReasonText
      )
    );
  }

  /* goToHome() {
    this.$state.go('home');
  } */
}
